import { useState, useEffect } from 'react';

const useBreakpoint = () => {
  const breakpoints = [
    { label: 'xs', width: 480 },
    { label: 'sm', width: 576 },
    { label: 'md', width: 768 },
    { label: 'lg', width: 992 },
    { label: 'xl', width: 1200 },
    { label: 'xxl', width: 1600 },
  ];

  const getBreakpoint = width => {
    const breakpoint = breakpoints.find((brk, index) => {
      if (index === 0 && width < breakpoints[0].width) return brk;
      if (index === 5 && width > breakpoints[5].width) return brk;
      if (width >= brk.width && width < breakpoints[index + 1].width) return brk;
      return null;
    });

    return breakpoint.label;
  };

  if (typeof window !== 'undefined') {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleWindowResize);

      return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const breakpoint = getBreakpoint(width);

    return { width, breakpoint };
  }
  return { breakpoint: breakpoints[0] };
};

export default useBreakpoint;
