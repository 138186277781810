import React from 'react';
import ImageGallery from 'react-image-gallery';
import Box from 'common/components/Box';
import 'react-image-gallery/styles/css/image-gallery.css';
import Image1 from 'common/assets/image/app/slide-1.png';
import Image2 from 'common/assets/image/app/slide-2.png';
import Image3 from 'common/assets/image/app/slide-3.png';
import Image4 from 'common/assets/image/app/slide-4.png';
import Image5 from 'common/assets/image/app/slide-5.png';

// import DomainSection from '../container/Hosting/Domain';
const images = [
  {
    original: `${Image1}`,
  },
  {
    original: `${Image2}`,
  },
  {
    original: `${Image3}`,
  },
  {
    original: `${Image2}`,
  },
  {
    original: `${Image4}`,
  },
  {
    original: `${Image5}`,
  },
];

const ImageSlider = () => {
  return (
    <Box className="FeatureSlider">
      <ImageGallery
        items={images}
        className="Slider-img"
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
        showBullets={true}
        autoPlay={true}
      />
    </Box>
  );
};

export default ImageSlider;
