import styled from 'styled-components';
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

export const LoginModalWrapper = styled.div`
  
`;


export const DriversContainer = styled.div``;

export const DialogTitleContainer = styled(DialogTitle)`
  h2.MuiTypography-root {
    display: flex;
    flex-direction: column;
    
    img {
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }
  }

  @media (max-width: 768px) {
    h2.MuiTypography-root {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

export const DialogContentContainer = styled(DialogContent)`
  &.MuiDialogContent-dividers {
    padding: 10px 70px 40px;
  }

  .step-title {
    line-height: 150%;
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 900;
  }
  
  .step-description {
    margin-top: 10px;
    text-align: center;
  }

  .step-result {
    font-size: 30px;
    text-align: center;
  }

  @media (max-width: 768px) {
    &.MuiDialogContent-dividers {
      padding: 10px 30px 40px;
    }
  }

  @media (max-width: 576px) {
    &.MuiDialogContent-dividers {
      padding: 10px 20px 30px;
    }
  }
`;

export const DialogActionsContainer = styled(DialogActions)`
  padding: 10px 24px;

  & > div {
    width: 100%;
  }

  button {
    min-width: 150px;

    @media (max-width: 576px) {
      min-width: 0;
      width: 100%;
    }
  }
`;

export const MyAutocompleteContainer = styled.div`
  .css-2b097c-container {
    z-index: 10;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    border-radius: 0;
    height: 54px;
    font-weight: 600;
    font-size: 15px;
    padding-left: 3px;
  }

  .css-1pahdxg-control,
  .css-1pahdxg-control:hover {
    border-color: #3f51b5;
  }
`;